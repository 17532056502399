import React, { useState, useEffect } from "react";
import { Badge, Form, Row, Col, Card, Pagination } from "react-bootstrap";
import catalog from "./catalog.json";

export default function ExpenceCatalog({ onSelect }) {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const [searchResults, setSearchResults] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setSearchResults(
      catalog.filter((category) => {
        return (
          category.name.toLowerCase().includes(search.toLowerCase()) ||
          category.description?.toLowerCase().includes(search.toLowerCase()) ||
          category.category?.toLowerCase().includes(search.toLowerCase())
        );
      }),
    );
  }, [search]);

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(searchResults.slice(indexOfFirstItem, indexOfLastItem));
    setTotalPages(Math.ceil(searchResults.length / itemsPerPage));
  }, [searchResults, currentPage]);

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formCategorySearch">
        <Form.Control
          type="text"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Zoek een categorie, of voeg een nieuwe toe"
        />
        <div className="mt-2">
          <Row>
            {currentItems.map((category) => (
              <Col sm={12} md={4} key={category.name} className="p-2">
                <Card
                  className="catalog-card h-100"
                  onClick={() => onSelect(category)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <Card.Body>
                    <h6>{category.name}</h6>
                    <small>{category.description}</small>
                    {/* <Badge bg="primary" className="float-end mt-2">
                      {category.category}
                    </Badge> */}
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {search !== "" && searchResults.length === 0 ? (
              <Col xs={12} sm={4} className="p-2">
                <Card
                  className="catalog-card h-100"
                  onClick={() => onSelect({ name: search })}
                  style={{ cursor: "pointer" }}
                >
                  <Card.Body>
                    {search}
                    <Badge bg="primary" className="float-end m-2">
                      Nieuw
                    </Badge>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
          </Row>
          {totalPages > 1 && (
            <Pagination className="justify-content-center my-3">
              <Pagination.Prev
                onClick={() =>
                  setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
                }
                disabled={currentPage === 1}
              />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage(
                    currentPage < totalPages ? currentPage + 1 : totalPages,
                  )
                }
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </div>
      </Form.Group>
    </Form>
  );
}
