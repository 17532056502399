import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Navigation from "./Navigation";
import AuthContext from "./context/AuthContext";
import AdvancedView from "./components/PersonView/AdvancedView";

export default function App() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <Navigation />
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}

        {user ? (
          <Route index path="/" element={<AdvancedView />} />
        ) : (
          <Route path="/" element={<AdvancedView />} />
        )}
      </Routes>
    </>
  );
}
