import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, ChartDataLabels);

export default function Graph({
  showPerMonth,
  btwPlichtig,
  income,
  incomeBTW,
  allExpences,
  expenceBTW,
  nonDeductibleExpences,
  deductibleExpences,
  socialTax,
  tax,
  netIncome,
}) {
  const [forYou, setForYou] = useState(0);
  const [saving, setSaving] = useState(0);

  useEffect(() => {
    const forYou = Math.max(0, Math.round(netIncome));
    const savings = income - netIncome - allExpences;

    setForYou(forYou);
    setSaving(savings);
  }, [income, netIncome, allExpences, showPerMonth]);

  const elements = [
    {
      label: "Uitgave",
      value: formatNumber(
        (allExpences - Math.max(nonDeductibleExpences, 0)) *
          (showPerMonth ? 1 / 12 : 1),
      ),
      color: "rgba(157, 2, 8, 0.8)",
    },
    {
      label: `Uitgave 
(Niet aftrekbaar)`,
      value: formatNumber(
        Math.max(nonDeductibleExpences, 0) * (showPerMonth ? 1 / 12 : 1),
      ),
      color: "rgba(142, 2, 8, 0.8)",
    },
    {
      label: "BTW",
      value: formatNumber(
        (incomeBTW - expenceBTW > 0 ? incomeBTW - expenceBTW : 0) *
          (showPerMonth ? 1 / 12 : 1),
      ),
      color: "rgba(208, 0, 0, 0.8)",
    },
    {
      label: "Belastingen",
      value: formatNumber(tax * (showPerMonth ? 1 / 12 : 1)),
      color: "rgba(220, 47, 2, 0.8)",
    },
    {
      label: "Sociale Bijdrage",
      value: formatNumber(socialTax * (showPerMonth ? 1 / 12 : 1)),
      color: "rgba(232, 93, 4, 0.8)",
    },
    {
      label: "Netto",
      value: formatNumber(
        Math.max(
          0,
          netIncome +
            (incomeBTW - expenceBTW < 0 ? Math.abs(incomeBTW - expenceBTW) : 0),
        ) * (showPerMonth ? 1 / 12 : 1),
      ),
      color: "rgba(244, 140, 6, 0.8)",
    },
  ];

  const data = {
    labels: elements.map((element) => element.label),
    datasets: [
      {
        data: elements.map((element) => element.value),
        backgroundColor: elements.map((element) => element.color),
        borderWidth: 0,
      },
    ],
  };

  function formatNumber(value) {
    if (value % 1 === 0) {
      return value.toString();
    } else {
      return value.toFixed(2).replace(/\.?0+$/, "");
    }
  }

  return (
    <>
      <Doughnut
        className="m-4"
        data={data}
        options={{
          hoverOffset: 20,
          layout: {
            padding: 20,
          },
          plugins: {
            datalabels: {
              formatter: function (value, context) {
                return value > 0
                  ? context.chart.data.labels[context.dataIndex]
                  : "";
              },
              color: "white",
              display: true,
            },
          },
        }}
      />
      <div className="d-flex justify-content-center fw-bold">
        <div>
          <p>
            <small>
              € {formatNumber(income * (showPerMonth ? 1 / 12 : 1))}
            </small>
          </p>
        </div>
      </div>
      {/* TODO: if btw terug, does not work */}
      <ProgressBar className="mx-5" style={{ height: 25 }}>
        <ProgressBar
          label={`VOOR JOU`}
          variant="light"
          now={Math.max(0, forYou / income) * 100}
          key={1}
          className="progress-bar-overlay"
        />
        <ProgressBar
          label={`OPZIJ ZETTEN`}
          variant="light"
          now={((income - netIncome) / income) * 100}
          key={2}
          className="progress-bar-overlay"
        />
        <ProgressBar
          label={`UITGAVE`}
          variant="light"
          now={(allExpences / income) * 100}
          key={3}
          className="progress-bar-overlay"
        />
      </ProgressBar>

      <ProgressBar className="mx-5 text-muted" style={{ height: 28 }}>
        <ProgressBar
          label={`€ ${Math.round(forYou * (showPerMonth ? 1 / 12 : 1))}`}
          variant="success"
          now={Math.max(0, forYou / income) * 100}
          key={1}
        ></ProgressBar>

        <ProgressBar
          label={`€ ${Math.round(saving * (showPerMonth ? 1 / 12 : 1))}`}
          variant="danger"
          now={(saving / income) * 100}
          key={2}
        />
        <ProgressBar
          label={`€ ${Math.round(allExpences * (showPerMonth ? 1 / 12 : 1))}`}
          style={{ backgroundColor: "rgba(157, 2, 8, 0.8)" }}
          now={(allExpences / income) * 100}
          key={3}
        />
      </ProgressBar>
    </>
  );
}
