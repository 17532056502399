import React from "react";
import { Spinner, Container } from "react-bootstrap";

export default function Loader() {
  return (
    <Container className="vh-100 d-flex align-items-center justify-content-center">
      <Spinner className="" />
    </Container>
  );
}
