import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  ButtonGroup,
  Button,
  Modal,
  FloatingLabel,
  InputGroup,
} from "react-bootstrap";
import ExpenceCatalog from "./ExpenceCatalog";

export default function EditExpence({
  show,
  handleClose,
  expence,
  save,
  remove,
}) {
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [amount, setAmount] = useState();
  const [period, setPeriod] = useState();
  const [btw, setBTW] = useState();
  const [vaa, setVAA] = useState();
  const [btwDeductibility, setBTWDeductibility] = useState();
  const [taxDeductibility, setTaxDeductibility] = useState();
  const [editingTitle, setEditingTitle] = useState(false);

  //TODO
  // afschrijvingsperiode > ni nodig
  // Editable titles/name
  // backend integration
  // opzij zetten en uitgave splitsen
  // uitgave afronden

  const initialize = () => {
    setId(expence?.id || "");
    setName(expence?.name || "");
    setAmount(expence?.amount || "");
    setPeriod(expence?.period || "maand");
    setVAA(expence?.vaa || "");
    setBTW(expence?.btw || "");
    setBTWDeductibility(expence?.btwDeductibility || "");
    setTaxDeductibility(expence?.taxDeductibility || "");
  };

  const onSelectFromCatalog = (expence) => {
    setName(expence?.name || "");
    setAmount(expence?.default?.amount || "");
    setPeriod(expence?.default?.period || "maand");
    setVAA(expence?.default?.vaa || "");
    setBTW(expence?.default?.btw || "");
    setBTWDeductibility(expence?.default?.btwDeductibility || "");
    setTaxDeductibility(expence?.default?.taxDeductibility || "");
  };

  useEffect(() => {
    if (show) {
      initialize();
    }
  }, [show]);

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        {editingTitle || (editingTitle && !name) ? (
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={() => setEditingTitle(false)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setEditingTitle(false);
              }
            }}
            autoFocus
          />
        ) : (
          <Modal.Title
            className="editable-modal-title px-1"
            onClick={() => setEditingTitle(true)}
          >
            {name}
          </Modal.Title>
        )}
        {!name && !editingTitle ? (
          <Modal.Title>Doorzoek de cataloog</Modal.Title>
        ) : null}
      </Modal.Header>
      <Modal.Body>
        {!name && !editingTitle ? (
          <ExpenceCatalog onSelect={onSelectFromCatalog} />
        ) : (
          <Form>
            <Row className="mb-3">
              <ButtonGroup type="radio" name="options" defaultValue={1}>
                {["maand", "kwartaal", "jaar"].map((selected) => (
                  <Button
                    key={selected}
                    variant={period === selected ? "primary" : "secondary"}
                    name={selected}
                    onClick={() => setPeriod(selected)}
                  >
                    {selected}
                  </Button>
                ))}
              </ButtonGroup>
            </Row>

            <Row>
              <InputGroup className="mb-2">
                <FloatingLabel
                  controlId="amount"
                  label="Bedrag (incl. btw)"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid value.
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  controlId="deductibility"
                  label="Aftrekbaarheid (in %)"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    type="number"
                    min="0"
                    max="100"
                    value={taxDeductibility}
                    onChange={(e) => setTaxDeductibility(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid value.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </InputGroup>

              <InputGroup className="mb-2">
                <FloatingLabel
                  controlId="btw"
                  label="BTW (in %)"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    type="number"
                    value={btw}
                    onChange={(e) => setBTW(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid value.
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  controlId="btwDeductibility"
                  label="BTW Aftrekbaarheid (in %)"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    type="number"
                    min="0"
                    max="100"
                    value={btwDeductibility}
                    onChange={(e) => setBTWDeductibility(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid value.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </InputGroup>
            </Row>
            <Row>
              {/* advanced  from here*/}

              <Form.Group as={Col} className="mb-3" controlId="formVAA">
                <Form.Label>Voordeel van alle aard (VAA)</Form.Label>
                <Form.Control
                  type="number"
                  value={vaa}
                  onChange={(e) => setVAA(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid value.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {name ? (
          <>
            {id ? (
              <Button variant="danger-outline" onClick={() => remove(id)}>
                Verwijderen
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => initialize()}>
                Terug
              </Button>
            )}
            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                if(id !== "") {
                  save({
                    id,
                    name,
                    amount,
                    vaa,
                    btw,
                    period,
                    btwDeductibility,
                    taxDeductibility,
                  })} else
                  save({
                    name,
                    amount,
                    vaa,
                    btw,
                    period,
                    btwDeductibility,
                    taxDeductibility,
                  })
                }
              }
            >
              Opslaan
            </Button>
          </>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
