import React, { useContext, useState, useEffect } from "react";
import { Nav, Navbar, Image, Dropdown, Button, Modal } from "react-bootstrap";
import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import AuthContext from "./context/AuthContext";

export default function Navigation() {
  const { user, googleLogin, logout } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if (user) {
      setShowLogin(false);
    }
  }, [user]);

  useGoogleOneTapLogin({
    disabled: user?? false,
    onSuccess: googleLogin,
    onError: () => {
      console.log('Login Failed');
    },
  });

  return (
    <>
      <Modal centered show={showLogin} onHide={() => setShowLogin(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Aanmelden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GoogleLogin
            shape="pill"
            auto_select
            itp_support
            onSuccess={googleLogin}
            onError ={() => console.log('Login Failed')}
          />
          <hr />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Navbar
        bg={"light"}
        variant={"light"}
        expand="lg"
        collapseOnSelect
        className="fixed-top p-0"
      >
        <Navbar.Brand className="border-bottom mx-3 me-auto" href="#">
          {/* <img
          alt="Belast"
          src="/*.svg"
          height="50"
          width="60"/> */}
          <h5
            className="m-0"
            style={{ fontFamily: '"Readex Pro", sans-serif' }}
          >
            BELAST<small>.online</small>
          </h5>
        </Navbar.Brand>
        {/* <Nav className="m-auto">
        <Nav.Link as={NavLink}  to={`/planner`}>
          <BsCalendarWeek/>
        </Nav.Link>
        <Nav.Link as={NavLink} to={`/klanten`}>
          <BsPeople/>
        </Nav.Link>
        <Nav.Link as={NavLink} to={`/scan`}>
          <BsUpcScan/>
        </Nav.Link>
        <Nav.Link as={NavLink} to={`/dashboard`}>
          <BsBarChart/>
        </Nav.Link>
        <Nav.Link as={NavLink} to={`/kasboek`}>
          <BsCashCoin/>
        </Nav.Link>
      </Nav> */}
        {user ? (
          <Dropdown drop="start" className="justify-content-end">
            <Dropdown.Toggle
              as={Button}
              className="m-2 p-0 profile"
              style={{ width: "35px", height: "35px", borderWidth: "2px" }}
            >
              <Image
                src={user.picture}
                roundedCircle
                className="w-100"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="m-2">
              <Dropdown.Item eventKey="1">Instellingen</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="4" onClick={logout}>
                Uitloggen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div className="m-2">
            <Button
              variant="outline-primary"
              onClick={() => setShowLogin(true)}
            >
              Aanmelden
            </Button>
          </div>
        )}
      </Navbar>
    </>
  );
}
